import translate from '../google_translate'


////////////////    GET ALL EVENTS    //////////////////////////
export async function fetchAllEvents(locale) {
    try {
        var envType = process.env.NODE_ENV
        let res = ""
        if (envType == "production") {
            res = await fetch(`https://www.tal3a.app/api/events/recent/`)
        } else {
            res = await fetch(`http://localhost:3000/api/events/recent/`)
        }
        const data = await res.json()
        const allEvents = data['suggest'] ? data['suggest'] : []
        const todayEvents = data['today'] ? data['today'] : []
        if (locale == 'en') {
            let tempSuggestEvents = []
            let tempTodayEvents = []
            for (const singleEvent of allEvents) {
                let EngEvent = singleEvent
                EngEvent['title'] = await translate(singleEvent['title'], 'ar', 'en')
                EngEvent['description_ar'] = await translate(singleEvent['description_ar'], 'ar', 'en')
                tempSuggestEvents.push(EngEvent)
            }
            for (const todayEvent of todayEvents) {
                let EngEvent = todayEvent
                EngEvent['title'] = await translate(todayEvent['title'], 'ar', 'en')
                EngEvent['description_ar'] = await translate(todayEvent['description_ar'], 'ar', 'en')
                tempTodayEvents.push(EngEvent)
            }
            return { "suggest": tempSuggestEvents, "today": tempTodayEvents }
        } else {
                       return { "suggest": allEvents, "today": todayEvents }

        }
    }
    catch (error) {
        console.log(error)
    }
}
