import Image from 'next/image'
import TitleLine from './title_line'
import Link from 'next/link'
import { useTranslations } from 'next-intl';
import { useSession } from "next-auth/react"


function WelcomeTitleCompenant() {
    const t = useTranslations('home');
    const { data: session } = useSession()

    return (
        <>
            <div className="md:w-3/4 lg:w-2/4 px-5 mx-auto mt-5 flex flex-col md:flex-row md:items-center">
                <div className="text-2xl font-light text-gray-800">
                    {t("Welcome")} ,
                </div>
                <div className="text-3xl py-2 font-medium text-gray-800">{
                    session && <div>{
                        session.user.name
                    }</div>
                }</div>
            </div>
        </>

    )
}

export default WelcomeTitleCompenant