import React from 'react'
import EmptyPage from "./empty_data"
import CardItem from '../components/card_item';
import TitleLine from '../components/title_line'
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';


function CardsGridView({ events  }) {
  const t = useTranslations('home');
  const { locale } = useRouter();
  const {suggest} = events

  return (
    <div className=" bg-gray-100">
      <TitleLine titleTxt={t('Suggested')} dirTxt={locale == 'ar' ? true : false} />
      <section id='cardSection' className='container  md:mx-auto my-10'>
        {suggest ?
          <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:w-5/6 w-11/12 mx-auto gap-x-4 gap-y-8 h-full " >
            {/* ---------------------- GRID VIEW COMPONANT ---------------------- */}
            {suggest.map((event, i) => (
              <CardItem key={event.id} CardItem={event} />
            ))
            }
          </div>
          : <EmptyPage />
        }
      </section >
    </div>
  )

}


export default CardsGridView



