// let fromLang = 'ar';
// let toLang = 'en';

const API_KEY = process.env.NEXT_GOOGLE_TRANSLATE;


const translate = async (text, fromLang, toLang) => {
    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += '&q=' + encodeURI(text);
    url += `&source=${fromLang}`;
    url += `&target=${toLang}`;
    url += `&format=text`;
    const res = await fetch(url, {
        //mode: 'no-cors',
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        }
    })

    if (res.status == 200) {
        const response = await res.json()
        const answer = response['data']['translations'][0]['translatedText']
        return answer

    } else {
        return text;
    }

}
export default translate