import { useState, useEffect } from 'react'
import Link from 'next/link';
import Image from 'next/image';
import { Disclosure, } from '@headlessui/react'
import { HiMenuAlt2, HiHome } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdContacts, IoMdCreate } from 'react-icons/io'
import { MdLanguage } from 'react-icons/md'
import { BiLogIn, BiLogOut } from 'react-icons/bi'
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useSession } from "next-auth/react"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar({ pageName }) {
  const { locale } = useRouter();
  const router = useRouter();
  const t = useTranslations('home');
  const { data: session } = useSession()

  const navigation = [
    {
      name: 'Home', href: '/'
    },
    {
      name: 'Create_Event', href: getCreateLink()
    },
    {
      name: 'Contact', href: '/#social_section'
    },
    // { name: 'Admin', href: '/admin' },
  ]

  GenerateNavigationList()

  function getCreateLink() {
    if (!session) {
      return "/events/signIn"
    } else {
      return "/events/create"
    }
  }

  function GenerateNavigationList() {
    const { data: session } = useSession()
    if (session) {
      navigation.push({
        name: 'SignOut', href: '/api/auth/signout',
      },)
    } else {
      navigation.push({
        name: 'SignIn', href: `${locale == "en" ? locale : ""}/events/signIn`,

      },)

    }
  }

  function GetIconComp(name) {
    switch (name) {
      case "Home":
        return (<HiHome className="block h-5 w-5 text-white mx-1" />)
      case "Create_Event":
        return (<IoMdCreate className="block h-5 w-5 text-white mx-1" />)
      case "Contact":
        return (<IoMdContacts className="block h-5 w-5 text-white mx-1" />)
      case "SignIn":
        return (<BiLogIn className="block h-5 w-5 text-white mx-1" />)
      case "SignOut":
        return (<BiLogOut className="block h-5 w-5 text-white mx-1" />)
      default:
        return (<BiLogOut className="block h-5 w-5 text-white mx-1" />)
    }

  }

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              {/* Mobile menu button*/}
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <AiOutlineClose className="block h-5 w-5" aria-hidden="true" />
                  ) : (
                    <HiMenuAlt2 className="block h-5 w-5" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* MENU */}
              <div className="flex flex-1 items-center justify-center sm:justify-between sm:items-stretch">
                <div>
                  <Link href={`/`}>
                    <div className="flex flex-shrink-0 items-center">
                      <Image
                        className="block h-8 w-auto lg:hidden"
                        width={50} height={50}
                        src={locale == "ar" ? "/Arabic-Tal3a.png" : "/Tal3a-Logo.png"}
                        alt="tal3a"
                        priority="true"
                      />
                      <Image
                        className="hidden h-8 w-auto lg:block"
                        width={50} height={50}
                        src={locale == "ar" ? "/Arabic-Tal3a.png" : "/Tal3a-Logo.png"}
                        alt="tal3a"
                        priority="true"

                      />
                    </div>
                  </Link>
                </div>
                {/******* Nav bar items  LARGE SCREEN  ******/}
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {/************ navigation List ***********/}
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        locale={locale}
                        className={classNames(
                          item.name === pageName ? 'bg-gray-900 text-white scroll-smooth' : 'text-gray-300 scroll-smooth hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md scroll-smooth text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}>
                        {t(`${item.name}`)}
                      </Link>
                    ))}
                    {/************ LANGUAGE SELECTOR ***********/}
                    <Link
                      href={locale == 'ar' ? '/en' : '/'}
                      locale={locale == 'ar' ? 'en' : 'ar'}
                      className='border border-neutral-400 text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md scroll-smooth text-sm font-medium'>
                      {locale == 'ar' ? "English" : "العربية"}
                    </Link>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item, i) => (
                <div key={item.name}
                  className="flex flex-row items-center">
                  {GetIconComp(item.name)}
                  <Link
                    key={item.name}
                    href={item.href}
                    locale={locale}
                    className={
                      classNames(
                        item.name === pageName ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                    aria-current={item.current ? 'page' : undefined}>
                    {t(`${item.name}`)}
                  </Link>
                </div>

              ))}
              <div className="flex flex-row items-center">
                <MdLanguage className="block h-5 w-5 text-white mx-1" />
                <Link 
                 href={router.asPath}
                  locale={locale == "ar" ? "en" : "ar"}
                  className='block w-full   text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-base font-medium'>
                  {locale == 'ar' ? "English" : "العربية"}
                </Link >
              </div>


            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}