import TitleLine from '../title_line'
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';

import Today_card from './today_card';



function TodayCompenant({ events }) {
    const t = useTranslations('home');
    const { locale } = useRouter();
    const { today } = events

    return (
        <>
            {today.length > 0 ?
                <div className='w-full mx-auto bg-gray-100 py-1'>
                    <TitleLine titleTxt={t('Today_Events')} dirTxt={locale == 'ar' ? true : false} />
                    <div
                        className={
                            today.length > 2
                                ? " w-11/12 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
                                : today.length > 1
                                    ? " w-5/6 lg:w-4/6 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2"
                                    : "container mx-auto w-10/12 md:w-6/12 lg:w-4/12 xl:w-4/12 2xl:w-3/12"}>
                        {today.map((event, index) =>
                            <Today_card key={event.id} event={event}></Today_card>
                        )}
                    </div>
                </div>
                : <></>
            }
        </>

    )
}

export default TodayCompenant

