import { useState, useEffect } from 'react'
import NavBar from '../components/nav_bar'
import CardGrid from '../components/card_grid'
import MobileSection from '../components/mobile_section'
import SocialSection from '../components/social_section'
import EmptyPage from '../components/empty_data'
import SearchComp from '../components/search_comp'
import { useTranslations } from 'next-intl';
import WelcomeTitleCompenant from '../components/welcome_title_comp'
import { fetchAllEvents } from '../core/events/all_events'
import TodayCompenant from '../components/today/today_comp'
import TodayScorllCompenant from '../components/today/scroll_today_comp'
import { fetchAllTags } from '../core/events/all_tags'

function Index({ events, tags }) {
  const [info, setInfo] = useState()
  const [isLoadingInfo, setLoadingInfo] = useState(true)
  const t = useTranslations('home');

  ////////////////    GET ALL Settings    //////////////////////////
  async function fetchAllSettingInfo() {
    try {
      const res = await fetch(`/api/settings`)
      const infoData = await res.json()
      const allInfo = infoData['data']
      setInfo(allInfo)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    fetchAllEvents()
    fetchAllSettingInfo().then(() => {
      setLoadingInfo(false)
    })

  }, [])

  return (
    <main className=' bg-gray-100'>
      {/*-------------- NavBar Section ------------------*/}
      <NavBar pageName={'Home'} />
      {/*-------------- WELCOME Section ------------------*/}
      {events && <WelcomeTitleCompenant />}
      {/*-------------- SEARCH Section ------------------*/}
      {events && <SearchComp url={`/events/`} tags={tags} />}
      {/*-------------- Today Section ------------------*/}
      {events && <TodayCompenant events={events} />}
      {/*-------------- Cards Grid Section ------------------*/}
      {events && <CardGrid events={events} />}
      {!events && <EmptyPage />}
       {/*-------------- Scorll Section ------------------*/}
       {events && <TodayScorllCompenant events={events} />}
      {/*-------------- Mobile Section ------------------*/}
      {!isLoadingInfo && <MobileSection info={{ info }} />}
      {/*-------------- Socail Icons Section ------------------*/}
      {!isLoadingInfo && <div id='social_section' className='scroll-smooth'>
        <SocialSection info={{ info }} />
      </div>}
    </main>

  )
}

export async function getServerSideProps({ locale }) {
  let events = []
  let tags = []
  try {
    events = await fetchAllEvents(locale);
    const tagsData = await fetchAllTags();
    tags = tagsData['allTags']
  } catch (error) {
    console.log("Sorry, Something went Wrong try again later")
  }

  return {
    props: {
      messages: require(`../locales/${locale}.json`),
      events,
      tags,
    },
    // revalidate: 10
  };
}


export default Index
