
import Image from 'next/image';

function EmptyPage() {

    return (
        <div className='flex flex-row h-[70vh] justify-center items-center'>
        <Image priority="true" src="/nodatafound.png" width={300} height={300} className="object-fill w-auto h-40" alt='no data tal3a'></Image>
      </div>
    )

}

export default EmptyPage
