


import TitleLine from '../title_line'
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';

import ScrollTodayCard from './scroll_today_card';



function TodayScorllCompenant({ events }) {
    const t = useTranslations('home');
    const { locale } = useRouter();
    const { today } = events



    return (
        <>
            {today.length > 0 ?
                <div className='mx-auto bg-gray-100 pb-3'>
                    <TitleLine titleTxt={t('Events')} dirTxt={locale == 'ar' ? true : false} />
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:w-5/6 w-10/12 mx-auto gap-x-4 gap-y-8 h-auto pt-10">
                        {
                            today.slice()
                                .reverse().map((event, index) =>
                                    <div key={event.id} className="place-items-center">
                                        <ScrollTodayCard key={event.id} event={event}></ScrollTodayCard>
                                    </div>
                                )}
                    </div>
                </div>
                : <></>
            }
        </>

    )
}

export default TodayScorllCompenant
