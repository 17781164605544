
const mainFunc = {
    "editUrl": function editImageUrl(urlTxt) {
        var urlEditedTxt = String(urlTxt).split('upload/')[1]
        var mainUrl = 'https://res.cloudinary.com/drmbo6ttf/image/upload/c_scale,w_300/'
        return mainUrl + urlEditedTxt;
    },
    "editUrlMD": function editImageUrl(urlTxt) {
        var urlEditedTxt = String(urlTxt).split('upload/')[1]
        var mainUrl = 'https://res.cloudinary.com/drmbo6ttf/image/upload/c_scale,w_500/'
        return mainUrl + urlEditedTxt;
    },
    "editUrlHD": function editImageUrl(urlTxt) {
        var urlEditedTxt = String(urlTxt).split('upload/')[1]
        var mainUrl = 'https://res.cloudinary.com/drmbo6ttf/image/upload/c_scale,w_700/'
        return mainUrl + urlEditedTxt;
    },
    "editUrlAdmin": function editImageUrl(urlTxt) {
        var urlEditedTxt = String(urlTxt).split('upload/')[1]
        var mainUrl = 'https://res.cloudinary.com/drmbo6ttf/image/upload/c_scale,w_20/'
        return mainUrl + urlEditedTxt;
    },
    "uploadImage": async function uploadImageToServer(imageFile, title) {
        console.log("=====================")
        try {
            const url = `https://api.cloudinary.com/v1_1/drmbo6ttf/image/upload`;
            const formData = new FormData();
            formData.append("file", imageFile);
            formData.append("upload_preset", "Tal3aApp");
            // formData.append("eager", "q_jpegmini,c_fill,w_200,h_150");
            formData.append("public_id", String(title).replaceAll(" ", "-"));
            const res = await fetch(url, {
                method: "POST",
                body: formData
            })
            console.log(res)
            if (res.status == 200) {
                const jsonData = await res.json()
                const urlimg = jsonData['secure_url']
                console.log(urlimg)
                return urlimg
            }
        } catch (error) {
            return "error"

        }
    },
}
export default mainFunc