import React from 'react'
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

function MobileSection({ info }) {
  const t = useTranslations('home');

  return (
   
        
          info['info'] &&
          <>

          <div className='h-auto flex flex-col py-14 px-3 text-center bg-third_color'>
          <p className='text-3xl text-black font-medium mb-3'>{t('Discover')}</p>
          <p className='text-2xl text-black font-normal'>{t('download')}</p>
          <div className='flex flex-col lg:flex-row  justify-center items-center p-5'>

            <div>
              <Link href={info['info']['apple']}>
                <Image priority="true" width={100} height={44} src="/mobile/app-store-badge.png" className='w-44 m-3 transition ease-in-out hover:scale-110 ' alt="tal3a apple app-store" />
              </Link>
            </div>
            <div>
              <Link href={info['info']['andriod']}>
                <Image priority="true" width={100} height={44} src="/mobile/google-play-badge.png" className='w-44 m-3 transition ease-in-out hover:scale-110 ' alt="tal3a apple app-store" />
              </Link>
            </div>
            </div>
    </div>
          </>
        


  
  )

}


export default MobileSection



