import React from 'react'


function TitleLine({ titleTxt, dirTxt }) {

  return (
    <div className="mt-5">
      {
        dirTxt ?
          <div className="bg-gray-800 w-fit px-10 py-3 rounded-tl-md rounded-bl-md inline-block text-white text-xl font-bold">{titleTxt}</div>
          : <div className="bg-gray-800 w-fit px-10 py-3 rounded-tr-md rounded-br-md inline-block text-white text-xl font-bold">{titleTxt}</div>
      }

    </div>
  )

}



export default TitleLine

