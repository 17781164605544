import Link from 'next/link'
import Image from 'next/image'
import { BiTimeFive } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx"
import moment from 'moment'
import { useRouter } from 'next/router';
import mainFunc from '../../core/main_func';
import { useTranslations } from 'next-intl';

export default function Today_card({ event }) {
    const t = useTranslations('single');
    const { locale } = useRouter();
    locale == 'en' ? moment.locale('en') : moment.locale('ar')

    return (
        <div className="mb-2 ease-in-out duration-200 hover:-translate-y-2" key={event.id}>
            <Link href={`/events/${event.id}`}  >
                <div className="rounded-md m-3 bg-white p-2 shadow-sm hover:shadow-md hover:shadow-gray-300 hover:drop-shadow-md transition ease-in-out">
                    <div className="h-16 w-full flex flex-row items-center justify-start rounded-t-md ">
                        <RxDotFilled className="h-auto text-left text-gray-800 text-3xl mb-1"></RxDotFilled>
                        <h1 className="h-auto text-center text-gray-800 text-xl line-clamp-2 mb-1 font-medium">{event.title.toString().toUpperCase()}</h1>
                    </div>
                    <div className="h-80" >
                        <Image priority="true" className=" w-full h-full rounded-t-md" alt={event.title + " Tal3a"} width={500} height={500} src={event.picture ? mainFunc['editUrlHD'](event.picture) : '/Tal3a-Logo.png'}>
                        </Image>
                    </div>
                    <div className="h-36 w-full flex flex-col content-center justify-center bg-gradient-to-br bg-gray-800 p-4 rounded-b-md">
                        <p className='text-md text-center text-white line-clamp-2 md:line-clamp-3 '>{(event.description_ar)}</p>
                        <div className="flex flex-col justify-center items-center pt-1">
                            <p className='md:text-md text-md mx-1 sm:text-base lg:text-lg text-white'>{t("Available until")} :
                                {moment.utc(event.end_time_date).local('en').format('D MMMM YYYY')}</p>
                            <div className="flex flex-row justify-center items-center pt-1">
                                <BiTimeFive color="white" className="text-xl" />
                                <p className='md:text-md text-md mx-1 sm:text-base lg:text-lg text-white'> {moment.utc(event.end_time_date).local('en').format('hh:mm a')}</p>


                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
