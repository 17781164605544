
import Link from 'next/link'
import Image from 'next/image'
import { BiTimeFive } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx"
import moment from 'moment'
import { useRouter } from 'next/router';
import mainFunc from '../../core/main_func';
import { useTranslations } from 'next-intl';

export default function Scroll_Today_card({ event }) {
    const t = useTranslations('single');
    const { locale } = useRouter();
    locale == 'en' ? moment.locale('en') : moment.locale('ar')

    return (
        <div className="mb-2" key={event.id}>
            <div key={event.id} className="place-items-center">
                {/* <ScrollTodayCard key={event.id} event={event}></ScrollTodayCard> */}
                <Link key={event.id} href={`events/${event.id}`}  >
                    <div className="flex flex-row bg-neutral-50 h-24 p-2 rounded-lg my-1 shadow-sm border border-neutral-200 hover:bg-neutral-100 hover:shadow-md transition ease-in-out hover:scale-105 ">
                        {/*------------ Image -------------- */}
                        <div className='h-24 w-36 -my-6'>
                            {
                                event.picture ? <Image className='rounded-lg object-fill w-full h-full shadow-lg' src={event.picture} alt={event.title} width={100} height={100} />
                                    : <Image className='rounded-lg object-fill w-full h-full shadow-lg pt-10 p-4' src='/Tal3a-Logo.png' alt={event.title} width={100} height={100} />
                            }
                        </div>
                        {/*------------ TITLE -------------- */}
                        <div className="flex flex-col items-center justify-center w-full">
                            <p className='sm:text-md md:text-xl lg:text-xl md:font-semibold font-bold text-center flex flex-col justify-center items-center line-clamp-2'>{event.title}</p>

                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}
