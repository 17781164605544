import React from 'react'
import { useRouter } from 'next/router';
import { MdDateRange } from 'react-icons/md';
import { BiTimeFive } from "react-icons/bi";
import Link from 'next/link';
import Image from 'next/image';
import moment from 'moment'
import mainFun from '../core/main_func'

import 'moment/locale/ar';


function CardItem({ CardItem }) {
  const { locale } = useRouter()
   locale == 'en'  ?   moment.locale('en') :moment.locale('ar')
  return (
    <>
      < Link href={`/events/${CardItem.id}`}  >
        <div className="bg-neutral-50 h-full p-2 rounded-lg my-1 min-h-[12rem] shadow-sm border border-neutral-200 hover:bg-neutral-100 hover:shadow-md transition ease-in-out hover:scale-105 ">
          <div className="h-[45vh] rounded-md min-h-[16rem]">
            {/*------------ Image -------------- */}
            <div className='h-3/6 -my-6'>
              {
                CardItem.picture ? <Image priority="true" className='rounded-lg object-fill w-full h-full shadow-lg' src={mainFun['editUrlMD'](CardItem.picture) } alt={CardItem.title} width={300} height={300} />
                  : <Image priority="true" className='rounded-lg object-fill w-full h-full shadow-lg pt-10 p-4' src='/Tal3a-Logo.png' alt={CardItem.title} width={300} height={300} />
              }
            </div>
            {/*------------ Card Data -------------- */}
            <div className="h-3/6 flex flex-col justify-items-end items-center">
              <div className='mt-8 md:mt-8'>
                {/*------------ TITLE -------------- */}
                <div className='whitespace-pre-line'>
                  <p className='text-base font-bold text-center md:text-md lg:text-lg line-clamp-2'>{(CardItem.title)}</p>
                </div>
                {/*------------ description_ar -------------- */}
                {CardItem.description_ar != null &&
                  <p className="text-base md:text-lg text-ellipsis line-clamp-2 font-normal text-gray-500 text-center m-1" >{String(CardItem.description_ar).substring(0, 80)}</p>
                }
                {/*------------ TIME DATE -------------- */}
                <div className='flex flex-row items-center justify-center'>
                  <MdDateRange size={15} />
                  <p className='md:text-md text-sm text-left mx-1 sm:text-xs lg:text-sm'>
                    {moment.utc(CardItem.time_date).locale(locale).format('D MMMM YYYY')}</p>
                </div>
                <div className='flex flex-row items-center justify-center'>
                  <BiTimeFive size={15} />
                  <p className='md:text-md text-sm text-left mx-1 sm:text-xs lg:text-sm'>
                    {moment.utc(CardItem.time_date).local(locale).format('hh:mm a')}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* </div> */}
      </Link>
    </>

  )

}


export default CardItem



