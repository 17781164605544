import React from 'react'
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

function SocialSection({ info }) {
  const t = useTranslations('home');
  return (
    <div className='h-auto flex flex-col py-10 text-center bg-gray-800'>
      {info['info'] && <p className='text-xl text-white font-medium'>{t('FOLLOW US')}</p>}
      <div className='flex flex-row justify-center'>
        {
          info['info'] &&
          <div className="flex flex-row">
            <Link href={info['info']['facebook']}>
              <Image priority="true" src="/social/Facebook_logo.svg" width={40} height={40} className='m-5 transition ease-in-out hover:scale-125' alt='Facebook_logo' />
            </Link>
            <Link href={info['info']['instagram']}>
              <Image priority="true" src="/social/Instagram_logo.svg" width={40} height={40} className='m-5 transition ease-in-out hover:scale-125' alt='Instagram_logo' />
            </Link>
          </div>
        }
      </div>
      <p className='text-sm text-gray-300 font-normal'>© Tal3a 2023. All Rights Reserved</p>
    </div>
  )

}


export default SocialSection



