import React from 'react'
import { useState } from 'react'
import Link from 'next/link'
import moment from 'moment'
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import translate from '../core/google_translate'
import LoadingComp from './loading_comp';
import Select from "react-dropdown-select";
import { MdFilterAlt, MdClose, } from 'react-icons/md';


const Search_comp = ({ url, tags }) => {
    const { locale } = useRouter()
    const [dataSearch, setDataSearch] = useState()
    const [selectedTags, setSelectedTags] = useState([])
    const [isFilter, setIsFilter] = useState(false)
    const [selectedDate, setSelectedDate] = useState()
    const [isloading, setIsloading] = useState(false)
    const t = useTranslations('result');
    let searchWords = ""


    const dateOptions = [

        {
            value: "Today",
            label: t("Today")
        },
        {
            value: "Tomorrow",
            label: t("Tomorrow")
        },
        {
            value: "This week",
            label: t("This week")
        },
        {
            value: "This month",
            label: t("This month")
        }
    ];


    async function fetchSearchData(searchData, searchDate) {
        setIsloading(true)
        let SearchWords = ""
        if (locale == 'en') {
            SearchWords = await translate(searchData, 'en', 'ar')
        } else {
            SearchWords = searchData
        }
        const res = await fetch(`/api/events/search`, {
            method: "POST",
            body: JSON.stringify({
                words: SearchWords,
                time: searchDate
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const json = await res.json()
        if (json['data'] == undefined) {
            setDataSearch()
            setIsloading(false)
        } else {
            if (locale == 'en') {
                const events = []
                for (var event of json['data']) {
                    var tempEvent = event
                    tempEvent['title'] = await translate(event['title'], 'ar', 'en')
                    tempEvent['description_ar'] = await translate(event['description_ar'], 'ar', 'en')
                    events.push(tempEvent)
                }
                setDataSearch(events)
                setIsloading(false)


            } else {
                setDataSearch(json['data'])
                setIsloading(false)
            }

        }
    }


    return (
        <div className="w-full">
            {/*-------------- Search Section ------------------*/}
            <div className="md:w-3/4 lg:w-2/4 w-full mx-auto pb-5">
                <form className="flex flex-col justify-center h-full items-center lg:mx-5" >
                    <div className='w-full h-20 p-5 flex flex-row justify-between items-center md:flex md:flex-row'>
                        {/* TEXT FIELD */}
                        <div className="relative w-full md:m-1 py-3">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                            </div>
                            <input
                                onChange={
                                    (event) => {
                                        event.preventDefault()
                                        if (document.getElementById("search").value) {
                                        } else {
                                            setDataSearch()
                                        }
                                    }
                                }
                                type="text" id="search" disabled={isloading} className="bg-gray-50 border border-gray-800 text-gray-900 text-sm rounded-lg focus:ring-gray-200 focus:border-gray-900 block w-full pl-10 p-2.5" placeholder={t("SEARCH")} />
                        </div>
                        <button
                            onClick={(event) => {
                                event.preventDefault()
                                if (document.getElementById("search").value) {
                                    fetchSearchData(document.getElementById("search").value)
                                } else {
                                    setDataSearch()
                                }

                            }} className="h-full px-3 m-1 flex flex-col justify-center items-center transition ease-in duration-200 uppercase rounded-md bg-gray-800 hover:shadow-md text-white hover:text-white border border-gray-900 focus:outline-none">
                            {t("Search")}
                            {/* <MdSearch size="18" /> */}

                        </button>
                        <button
                            onClick={(event) => {
                                event.preventDefault()
                                if (!isFilter) {
                                    setDataSearch("")
                                    setSelectedDate("")
                                    setSelectedTags([])
                                }
                                setIsFilter(!isFilter)
                            }}
                            className={isFilter ? "h-full flex flex-col justify-center items-center w-12 transition ease-in duration-200 uppercase rounded-md bg-white hover:shadow-md text-white hover:text-white border border-gray-900 focus:outline-none"
                                :
                                "h-full px-3 flex flex-col justify-center items-center w-12 transition ease-in duration-200 uppercase rounded-md bg-gray-800 hover:shadow-md text-white hover:text-white border border-gray-900 focus:outline-none"
                            }>
                            {!isFilter ? <MdFilterAlt /> : <MdClose color="#000" size={15} />}
                        </button>
                    </div>
                </form>
                {/*-------------- Search FILTER ------------------*/}
                {isFilter &&
                    <div className="p-3 w-11/12 flex flex-col justify-center items-start lg:flex md:flex-row lg:items-center lg:justify-start transition-all ease-in duration-400">
                        <div className="flex flex-row items-center justify-center w-full md:w-6/12 pb-3 md:p-0">
                            <h1 className="px-3">{t("Date") + " : "}</h1>
                            <div className="text-center w-8/12">
                                <Select
                                    style={{
                                        width: "auto",
                                        textAlign: 'center',
                                    }}
                                    color='#1F2937'
                                    options={dateOptions}
                                    searchBy="label"
                                    clearable='true'
                                    placeholder={t("All time")}
                                    onChange={
                                        (values) => {
                                            setSelectedDate(values[0]['value'])
                                            fetchSearchData(searchWords, values[0]['value'])
                                            ////////


                                            /////////
                                        }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-center w-full md:w-6/12">
                            <h1 className="px-3" >{t("Tags") + " : "}</h1>
                            <div className="text-center w-8/12">
                                <Select
                                    style={{
                                        width: "auto",
                                        textAlign: 'center',

                                    }}

                                    color='#1F2937'
                                    multi='true'
                                    searchBy='label'
                                    valueField='name'
                                    labelField='name'
                                    clearable='true'
                                    options={tags}
                                    contentRenderer={() => {
                                        return (selectedTags.length > 0)
                                            ? selectedTags.length + " " + t("Selected")
                                            : t("Select tags")
                                    }}
                                    placeholder={t("Select tags")}
                                    onChange={(values) => {
                                        setSelectedTags(values)
                                        for (let index = 0; index < values.length; index++) {
                                            if (index > 0) {
                                                searchWords += " " + values[index]['name']
                                            } else {
                                                searchWords = values[index]['name']
                                            }
                                        }
                                        if (searchWords != "") {//&& searchWords.length > 0) {
                                            fetchSearchData(searchWords)
                                        } else {
                                            setDataSearch("")
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div >
            {isloading && <LoadingComp isFullPage={false} />}
            {/*-------------- Search RESULTS ------------------*/}
            {!isloading && dataSearch && dataSearch.length > 0 &&
                <>
                    <div className="mb-7">
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:w-5/6 w-11/12 mx-auto gap-x-4 gap-y-8 h-auto" >
                            {dataSearch.map((event, i) => (
                                < Link key={event.id} href={`${url}${event.id}`}  >
                                    <div className="bg-neutral-50 h-full p-2 rounded-lg my-1 min-h-[12rem] shadow-sm border border-neutral-200 hover:bg-neutral-100 hover:shadow-md transition ease-in-out hover:scale-105 ">
                                        <div className="h-[30vh] md:h-[40vh] rounded-md min-h-[12rem]">
                                            {/*------------ Image -------------- */}
                                            <div className='md:h-3/6 h-3/5 -my-6'>
                                                {
                                                    event.picture ? <Image className='rounded-lg object-fill w-full h-full shadow-lg' src={event.picture} alt={event.title} width={200} height={200} />
                                                        : <Image className='rounded-lg object-fill w-full h-full shadow-lg pt-10 p-4' src='/Tal3a-Logo.png' alt={event.title} width={200} height={200} />
                                                }
                                            </div>
                                            {/*------------ Card Data -------------- */}
                                            <div className='flex flex-col justify-center md:h-3/6 h-3/5 mt-2 md:mt-8'>
                                                {/*------------ TITLE -------------- */}
                                                <p className='sm:text-md md:text-xl lg:text-xl md:font-semibold font-bold text-center flex flex-col justify-center items-center line-clamp-1'>{event.title}</p>
                                                {/*------------ description_ar -------------- */}
                                                {event.description_ar != null &&
                                                    <p className="text-sm font-normal text-gray-500 text-center line-clamp-2 md:line-clamp-2 lg:line-clamp-3 overflow-hidden m-1" >{event.description_ar}</p>
                                                }
                                                {/*------------ TIME DATE -------------- */}
                                                {/* <div className='flex flex-col mt-1 h-2/6 justify-center items-center'>
                                                    <div className='flex flex-row items-center justify-center mb-1'>
                                                        <MdDateRange size={15} />
                                                        <p className='md:text-md text-sm text-left mx-1 sm:text-xs lg:text-sm'>
                                                        ينتهي في {moment(event.end_time_date).locale(locale).format('D MMMM YYYY')}</p>
                                                    </div>
                                                    <div className='flex flex-row items-center justify-center'>
                                                        <BiTimeFive size={15} />
                                                        <p className='md:text-md text-sm text-left mx-1 sm:text-xs lg:text-sm'>
                                                            {moment(event.end_time_date).locale(locale).format(' h:mm a')}</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                            }

                        </div>

                    </div>
                </>
            }
            {!isloading && dataSearch && dataSearch.length == 0 &&
                <>
                    <div className="flex flex-row justify-center items-center w-9/12 mx-auto">
                        <h1 className="font-medium text-xl">😢 No results found </h1>
                    </div>
                </>
            }
        </div>

    )
}


export default Search_comp